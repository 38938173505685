/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Head() {

  return (
    <Helmet>
      <script>
        {`
          (function () {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://app.termly.io/embed.min.js';
            s.id = '454a51b6-5b23-41fc-a1fd-c82290c77f90';
            s.setAttribute("data-name", "termly-embed-banner");
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          })();
        `}
      </script>
    </Helmet>
  )
}

export default Head
