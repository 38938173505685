import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import Head from "../components/head"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Features from "../components/features"
import Explainer from "../components/explainer"
import Plan from "../components/plan"
import SimpleLogos from "../components/simplelogos"
import FAQ from "../components/faq"
import Pricing from "../components/pricing"
import Tasks from "../components/tasks"
import Testimonials from "../components/testimonials"
import CTA from "../components/cta"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import partnerLogo from "../images/shopify-partner-logo.png"
import partnerLogos from "../images/partner-logos@4x.png"

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "unlimited-hero@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    findProblemsImage: file(relativePath: { eq: "find-problems@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    greatJobImage: file(relativePath: { eq: "great-job@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    taskCompletedImage: file(relativePath: { eq: "task-completed@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const UnlimitedPage = ({data}) => (
  <Layout>
    <Head/>
    <SEO title="Home" />
    <Hero
      headline={(<span>Ready for <u>unlimited</u> Shopify tech support?</span>)}
      dek="You’re building a business. Technical issues shouldn’t throttle your growth. For one monthly fee, you can send us unlimited tasks and go focus on what matters."
      hero={
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      }
      cta="Try for free"
      ctaLink="javascript:document.getElementById('launchFreeTrial').click();"
      bullets={[
        {icon: "ti-check-box", text: "Wipe out design flaws, theme bugs, and tweaks"},
        {icon: "ti-settings", text: "Tackle complicated app installations and configurations"},
        {icon: "ti-bar-chart", text: "Watch your store performance soar, along with your conversion rates"}
      ]}/>
    <Features
      logo={partnerLogo}
      nuts={[
        {
          icon: "ti-timer",
          heading: "Blazing fast work",
          text: "You don’t have time to wait. Who does? We get through your tasks in a single day. Our speed never comes at the expense of quality, though."
        },
        {
          icon: "ti-heart",
          heading: "Quick and easy connections",
          text: "You can reach us via email, text, phone, and chat; whatever’s convenient for you. We promise a response in an hour, too. "
        },
        {
          icon: "ti-star",
          heading: "Work with Shopify all stars",
          text: "Our talented team consists of folks who have mastered the Shopify universe. Our geniuses can handle any issue, large or small."
        }
      ]}
    />
    <SimpleLogos
      logos={partnerLogos}
    />
    <Plan
      headline="We are here for you."
      dek={(<span>
                You don’t have to be a superhero to run your Shopify business, but
                it often feels like you do. You’re battling app vendors with
                flakey integrations, fighting through unclear support channels,
                all while the most unpredictable market throws new complications
                your way daily. Batman had Alfred. You have us. We’re your secret
                weapon that helps propel you to victory, each and every time. We
                are your virtual technical support team, ready to hop in wherever
                and however you need us.<br/><br/>
                Our founders forged careers running teams at the biggest tech
                companies, including Amazon, Google, and Microsoft. We’re a
                dependable, responsive, and enterprise-ready technical desk for
                your Shopify business. Let us help you be the business hero you
                already are.</span>)}
      ctaLink="javascript:document.getElementById('launchFreeTrial').click();"
      steps={[
        {heading:'Create your tasks', text:'Send your todo list, wish list, and any ad hoc requests as they come.'},
        {heading:'Work with an expert', text:'Watch your task list disappear with real time updates on progress.'},
        {heading:'Do your thing', text:'Focus on growing your business and keeping your customers happy.'}
      ]}
    />
    <Explainer
      headline="What you'll get"
      sections={[
        {
          heading:"TKTKTK Feature 1",
          image: <Img fluid={data.findProblemsImage.childImageSharp.fluid} />,
          text:"Get the help you need, when and where you need it. Discover a team that is available via video, phone, chat, email, and sms for real time updates and questions."
        },
        {
          heading:"TKTKTK Feature 2",
          image: <Img fluid={data.taskCompletedImage.childImageSharp.fluid} />,
          text:"Burn through your store's todo list in no time. You'll love working with an experienced team available on demand, you can spend less time talking about the work and more time watching it get done."
        },
        {
          heading:"TKTKTKT Feature 3",
          image: <Img fluid={data.greatJobImage.childImageSharp.fluid} />,
          text:"Achive your vision faster. Deliver real results for your store and get time to focus on the strategic initiatives you know will move the needle."
        }
      ]}
    />

    <Testimonials/>
    <Tasks
      icon="ti-check-box"
      headline="What can you get done?"
      dek="Check out these recently completed tasks for happy store owners."
      tasks={[
        {heading:'Add and style gifting options to the checkout', type:'support', time:'2 days', icon:'ti-support', csat:9},
        {heading:'Add description text to the collection pages', type:'support', icon:'ti-support', time:'1 days', csat:10},
        {heading:'Add zoomable images to the theme', type:'support', icon:'ti-support',time:'3 days', csat:8},
        {heading:'Look into lighthouse performance score', type:'investigate', icon:'ti-light-bulb', time:'2 days', csat:9},
        {heading:'Investigate why sales spiked on Friday', type:'report', icon:'ti-write', time:'1 days', csat:10},
        {heading:'Fix spacing below the image carousel', type:'support', icon:'ti-support', time:'1 days', csat:9},
        {heading:'Fix an error with in the theme', type:'support', time:'1 days', icon:'ti-support', csat:10},
        {heading:'Solve the issues with google shopping integration', type:'support', time:'3 days', icon:'ti-support', csat:9},
      ]}
    />
    <FAQ/>
    <CTA
      heading="Take us for a spin, create a free task..."
      cta="Try for free"
      ctaLink="javascript:document.getElementById('launchFreeTrial').click();"
    />
  </Layout>
)

export default UnlimitedPage
